import { createReducer, createActions } from 'reduxsauce'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  setFirstLogin: ['firstLogin'],
  setUser: ['user'],
  // SETTINGS MOSTY
  setGoogleAuth: ['googleAuth'],
  setNotificationsDecision: ['notificationsDecision'],

  setToken: ['token'],
  loginByFacebookRoutine: ['accessToken'],
  loginByAppleRoutine: ['accessToken'],
  loginByGoogleRoutine: ['accessToken'],
  logout: [],
  signUpRoutine: ['credentials'],
  loginByEmailRoutine: ['credentials'],
  setSubscription: ['subscription'],
  setPlan: ['plan'],
  setLevel: ['level'],
  setAchievementsList: ['achievementsList'],
  setConsultantProfile: ['consultantProfile'],
  setReferralCode: ['code'],
  recommendTheApp: [],
  refreshSubscription: [],
  setStatusMyAccount: ['status'],
  setViewedFinancial: ['viewedFinancial'],
  setExplanationPopupShowed: ['explanationPopupShowed'], // last time explanation popup was showed in client screen
  setLastChancePopupShowed: ['lastChancePopupShowed'], // last time functionality incentive popup was shown in client screen
  setLastLoginDate: ['lastLoginDate'] // Last time the last login was recorded
})

export const UserTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = {
  firstLogin: true,
  user: null,
  token: null,
  subscription: null,
  level: {},
  plan: undefined, // undefined = don't KNOW the plan ; null = don't HAVE a plan
  consultantProfile: null,
  achievementsList: [],
  referralCode: null,
  statusMyAccount: 'Cadastro',
  viewedFinancial: false,
  explanationPopupShowed: null,
  lastChancePopupShowed: null,
  lastLoginDate: null,
  notificationsDecision: false
}

/* ------------- Reducers ------------- */

export const setFirstLogin = (state, { firstLogin }) => {
  return { ...state, firstLogin }
}

export const setUser = (state, { user }) => {
  return { ...state, user }
}

export const setGoogleAuth = (state, { googleAuth }) => {
  return { ...state, googleAuth }
}

export const setNotificationsDecision = (state, { notificationsDecision }) => {
  return { ...state, notificationsDecision }
}

export const setToken = (state, { token }) => {
  return { ...state, token }
}

export const setConsultantProfile = (state, { consultantProfile }) => {
  return { ...state, consultantProfile }
}

export const setSubscription = (state, { subscription }) => {
  return { ...state, subscription }
}

export const setLevel = (state, { level }) => {
  return { ...state, level }
}

export const setPlan = (state, { plan }) => {
  if (typeof plan === 'object' && Object.entries(plan).length === 0) {
    // user with cancelled plan
    return { ...state, plan: null }
  } else {
    return { ...state, plan: plan }
  }
}

export const setAchievementsList = (state, { achievementsList }) => {
  const achievements = achievementsList.map(
    ({ id, achievementId, status, achievement }) => ({
      id,
      achievementId,
      status,
      achievement: {
        label: achievement.label,
        rewards: achievement.rewards
      }
    })
  )
  return { ...state, achievementsList: achievements }
}

export const setReferralCode = (state, { code }) => {
  return { ...state, referralCode: code }
}

export const setStatusMyAccount = (state, { status }) => {
  return { ...state, statusMyAccount: status }
}

export const setViewedFinancial = (state, { viewedFinancial }) => {
  return { ...state, viewedFinancial }
}

export const setExplanationPopupShowed = (
  state,
  { explanationPopupShowed }
) => {
  return { ...state, explanationPopupShowed }
}

export const setLastChancePopupShowed = (state, { lastChancePopupShowed }) => {
  return { ...state, lastChancePopupShowed }
}

export const setLastLoginDate = (state, { lastLoginDate }) => {
  return { ...state, lastLoginDate }
}

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_FIRST_LOGIN]: setFirstLogin,
  [Types.SET_USER]: setUser,
  [Types.SET_GOOGLE_AUTH]: setGoogleAuth,
  [Types.SET_NOTIFICATIONS_DECISION]: setNotificationsDecision,
  [Types.SET_TOKEN]: setToken,
  [Types.SET_SUBSCRIPTION]: setSubscription,
  [Types.SET_PLAN]: setPlan,
  [Types.SET_LEVEL]: setLevel,
  [Types.SET_CONSULTANT_PROFILE]: setConsultantProfile,
  [Types.SET_ACHIEVEMENTS_LIST]: setAchievementsList,
  [Types.SET_REFERRAL_CODE]: setReferralCode,
  [Types.SET_STATUS_MY_ACCOUNT]: setStatusMyAccount,
  [Types.SET_VIEWED_FINANCIAL]: setViewedFinancial,
  [Types.SET_EXPLANATION_POPUP_SHOWED]: setExplanationPopupShowed,
  [Types.SET_LAST_CHANCE_POPUP_SHOWED]: setLastChancePopupShowed,
  [Types.SET_LAST_LOGIN_DATE]: setLastLoginDate
})
